@import "../../base.scss";

.chart {
    
    background-image: url("/imgs/hydro-lines.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    position: relative;
    width: 300px;
    height: 300px;
    @media all and (max-width: 410px) {
        width: 250px;
        height: 250px;
    }
    .chart-axis {
        position: relative;
        height: 100%;
        width: 100%;
        border-left: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: -6px;
            width: 10px;
            height: 10px;
            border-top: 1px solid #ffffff;
            border-left: 1px solid #ffffff;
            @include transform(rotate(45deg));
        }
        &:after {
            content: "";
            position: absolute;
            bottom: -6px;
            right: 0;
            width: 10px;
            height: 10px;
            border-top: 1px solid #ffffff;
            border-right: 1px solid #ffffff;
            @include transform(rotate(45deg));
        }
    }
    .axis-x,
    .axis-y {
        position: absolute;
    }
    .axis-x {
        top: 105%;
        left: 0;
    }
    .axis-y {
        bottom: 25%;
        left: -40%;
        @include transform(rotate(-90deg));
        @media all and (max-width: 410px) {
            bottom: 30%;
            left: -42%;
        }
    }
    .circle-box,
    .circle {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: 100%;
    }
    .circle {
        z-index: 2;
        border: 2px solid #ffffff;
    }
    .fill {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 25%;
        background-color: rgba(255, 255, 255, 0.5);
    }
}
