@import "../../base.scss";

.solution-m {
    background-image: url("/imgs/hydropro-references-m.jpg");
    .container-m {
      padding-left: 20px;
      padding-right: 20px;
    }
    .content-m {
      background-color: $content-bg;
      padding: 20px;
    }

    h2 {
        text-align: center;
        padding-bottom: 40px;
    }

    .product-gallery-m {
        position: relative;
        padding-bottom: 80px;
        .product-img {
            text-align: center;
            img {
                max-width: 100%;
                display: block;
                height: auto;
                margin: auto;
            }
        }
        ul.slick-dots {
            bottom: -20px;
            display: flex !important;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            li {
                display: flex;
                flex: 1;
                width: auto;
                height: auto;
                margin: 0;
                padding: 0 5px;
                button {
                    background-color: #e7e7e7;
                    margin: 0;
                    padding: 0;
                    opacity: 0.25;
                    height: 2px;
                    overflow: hidden;
                    font-size: 1px;
                    line-height: 1px;
                    width: 100%;
                    border: 0;
                    &:before {
                        display: none;
                    }
                }
            }
            li.slick-active {
                button {
                    border: 0;
                    background-color: #e7e7e7;
                    opacity: 1;
                }
            }
        }
    }

    .bttn-make-query-m {
        position: relative;
        padding-bottom: 30px;
        display: flex;
        justify-content: center;
        .bttn {
            max-width: 250px;
            background-color: transparent;
            border:1px solid #ffffff;
            color:#ffffff;
        }
    }

    .tabs-list-m {
        padding: 10px 25px 30px 25px;
        @media all and (max-width:$bp-mobile-sm){
            padding: 10px 0 30px 0;
        }
        > div {
            margin-bottom: 20px;
        }
        .foldable-tab {
            .tab-name {
                border-bottom: 1px solid #1fecff;
            }
        }
        .tab-name {
            display: inline-block;
            padding: 10px 10px 10px 0px;
            margin-bottom: 10px;
            font-size: $font-size-md;
            font-family: $font-family-bold;
            .icon {
                font-size: 12px;
                padding-left: 10px;
            }
        }

        .techinfo-item,
        .drawings-item {
            padding-bottom: 5px;
        }
    }
}
