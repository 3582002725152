@import "../../base.scss";

.specialized-materials-gallery {
    position: relative;
    margin: 0;
    padding: 0;
    height: 100%;
    @extend %flex;
    flex-direction: column;

    .photos {
        position: relative;
        height: 60%;
        margin-left: -5px;
        margin-right: -5px;
        @extend %flex;
        flex-direction: column;
        overflow: hidden;

        .thumb {
            cursor:pointer;
            @include transition(all 0.5s ease-in-out);
            position: absolute;
            width: 30%;
            height: 50%;
            top:0;
            left: 70%;
            padding: 5px;

            &:nth-of-type(2) {
                top: 50%;
                @include transition(all 0.5s ease-in-out);
            }
            
            .photo {
                @include transition(all 0.5s ease-in-out);
                position: relative;
                @include box-shadow($box-shadow);
                opacity: .3;
                height:100%;
                width:100%;
                overflow: hidden;
                img {
                    position: absolute;
                    min-height:100%;
                    height:100%;
                    min-width: 100%;
                    max-width: none;
                    width:auto;
                    display:block;
                    top: 50%;
                    @include transform(translateY(-50%));
                }
            }

            &.thumb-active {
                @include transition(all 0.5s ease-in-out);
                position: absolute;
                top: 0;
                left: 0;
                width: 70%;
                height: 100%;
                .photo {
                    opacity: 1;
                }
            }
        }
    }

    .info {
        position: relative;
        height: 40%;
        padding-top: 30px;
        padding-bottom: 35px;
        h3 {
            padding-bottom: 10px;
            font-size: $font-size-md;
            font-family: $font-family-medium;
        }
        p {
            margin: 0;
            padding: 0;
            font-size: $font-size;
        }
        .photo-info {
            position: relative;
            height: 100%;
            padding: 20px 10px;
            border: 1px solid $base-color;
            background-color: $content-bg-3;
            border-radius: $border-radius-sm;

            @media all and (max-width: 1100px) {
                padding: 20px 15px;
            }
        }

        .hide {
            display: none;
            @include transition($animation);
        }
        .show {
            display: block;
            @include transition($animation);
        }
    }
}
