@import "../../base.scss";

.calculator-hydraulic-sizing {
    .row-bttn {
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: right;
        .bttn {
            width: auto;
            font-size: $font-size;
            padding-left: 30px;
            padding-right: 30px;
            background-color: rgba(255, 255, 255, 0.25);
        }
    }

    .col-30 {
        width: 30%;
        @media all and (max-width: 768px) {
            width: 100%;
        }
    }
    .col-50 {
        width: 50%;
        @media all and (max-width: 768px) {
            width: 100%;
        }
    }

    .row {
        &.row-top-space {
            padding-top: 50px;
            @media all and (max-width: 480px) {
                padding-top: 0;
            }
        }
        &.row-bottom-space {
            padding-bottom: 50px;
            @media all and (max-width: 768px) {
                padding-bottom: 30px;
            }
        }
        @media all and (max-width: 480px) {
            &.row-cols-480px {
                flex-direction: column;
                .col {
                    padding-bottom:30px;
                    .label {
                        padding-bottom:0;
                    }
                }
            }
         }
    }

    
    .section {
        display: block;
        background-color: #545454;
        padding: 30px 60px;
        margin-bottom: 20px;
        @include box-shadow($box-shadow-2);
        border-radius: $border-radius-sm;

        @media all and (max-width: $getscreen-mobile) {
            padding: 40px;
        }
        @media all and (max-width: 768px) {
            padding: 20px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        > .row {
            margin-left: -30px;
            margin-right: -30px;
            @media all and (max-width: 768px) {
                margin-left: 0;
                margin-right: 0;
                flex-direction: column;
            }
           
            > .col {
                padding-left: 30px;
                padding-right: 30px;
                @media all and (max-width: 768px) {
                    padding-left: 0;
                    padding-right: 0;
                    padding-bottom: 20px;
                    width: 100%;
                }
            }
        }
        .col {
            flex-basis: auto;
            flex-grow: initial;
            @media all and (max-width: 768px) {
                width: 100%;
            }
            @media all and (max-width: 480px) {
                .select {
                   width:100%;
                }
            }
        }

        .form-row {
            padding-bottom: 16px;
            &:last-child {
                padding-bottom: 0;
            }
        }

        h4 {
            font-family: $font-family-bold;
            font-size: 20px;
            line-height: 1.4em;
            margin: 0;
            padding: 0 0 20px 0;
            @media all and (max-width: 768px) {
                font-size: 16px;
            }
        }
        > h4 {
            padding-bottom: 20px;
        }

        .label {
            display: block;
            padding-bottom: 15px;
            font-size: $font-size-sm;
        }

        .radios-group {
            > div {
                padding-bottom: 20px;
                &:last-child {
                    padding-bottom: 0;
                }
            }
            @media all and (max-width: 768px) {
                // .row {
                //     flex-direction: column  ;
                // }
            }
        }

        .sub-item {
            padding-left: 50px;
            @media all and (max-width: 768px) {
                padding-left: 30px;
            }
        }

.select-box{
    width: 15vh;
}
        &.section-result {
            margin-top: 20px;
            margin-bottom: 20px;
            > .row {
                > .col {
                    width: 50%;
                    @media all and (max-width: 768px) {
                        width: 100%;
                    }
                    &:nth-child(1) {
                        .row {
                            padding-bottom: 20px;
                            &.row-bottom-space {
                                padding-bottom: 50px;
                                @media all and (max-width: 768px) {
                                    padding-bottom: 30px;
                                }
                            }

                            @media all and (max-width: 768px) {
                                &.row-water-quantity {
                                    > .col {
                                        width: 50%;
                                    }
                                    > .col:nth-child(2),
                                    > .col:nth-child(3) {
                                        width: 25%;
                                    }
                                }
                            }
                            @media all and (max-width: 480px) {
                                justify-content: space-between;
                                &.row-filling-height {
                                    input.input {
                                        width: 40px;
                                    }
                                }
                                &.row-water-quantity {
                                    > .col {
                                        width: 60%;
                                    }
                                    > .col:nth-child(2) {
                                        width: 20%;
                                    }
                                    > .col:nth-child(3) {
                                        width: 20%;
                                        padding-right: 0;
                                        justify-content: flex-end;
                                    }
                                }
                            }

                            .col {
                                display: flex;
                                align-items: center;
                                min-height: $form-control-height;
                                @media all and (max-width: 768px) {
                                    width: 50%;
                                }
                                @media all and (max-width: 480px) {
                                    width: auto;
                                }
                            }

                            .col:nth-child(1) {
                                width: 50%;
                                @media all and (max-width: 480px) {
                                    width: 75%;
                                    padding-right: 0;
                                }
                            }
                            @media all and (max-width: 480px) {
                                .col:nth-child(2) {
                                    padding-left: 0;
                                    padding-right: 10px;
                                    justify-content: flex-end;
                                }
                            }
                        }
                    }

                    &.col-chart {
                        padding-bottom: 40px;
                        @include transform(translateX(20px));
                        @media all and (max-width: 410px) {
                            @include transform(translateX(10px));
                        }
                        div {
                            margin: auto;
                        }
                    }
                }
            }

            h5 {
                font-size: $font-size;
                padding-bottom: 20px;
            }
        }
    }
    .bttn-ask{
        background-color: white !important;
    }
    #s1 {
        > .row {
            > .col:nth-child(1) {
                min-width: 50%;
                @media all and (max-width: 768px) {
                    width: 100%;
                }
            }
            > .col:nth-child(2) {
                width: 66%;
                @media all and (max-width: 768px) {
                    width: 100%;
                }
            }
        }
    }
} /* end calculator */
