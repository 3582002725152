@import "../../base.scss";

.solution {
  background-image: url("/imgs/hydropro-references.jpg");
  
  .content {
    > .row {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      height: 100%;
      width: 100%;
      padding-top: 20px;
      .col {
        padding: 0 30px;
        flex-basis: auto;
        flex-grow: initial;

        &.col-solution-text {
          width: 65%;
          padding-top: 0;
          padding-bottom: 0;
        }
        &.col-solution-gallery {
          padding-top: 40px;
          position: relative;
          width: 35%;
          overflow: hidden;
        }
      }
    }
  }

  .tabs-list {
    position: relative;
    height: 100%;

    .react-tabs {
      position: relative;
      height: 100%;
      padding-bottom: 30px;
      @extend %flex;
      flex-direction: column;

      .scroll {
        margin: 0;
        height: 100%;
      }
      padding-top: 90px;
      h3 {
        margin: 0;
        padding: 0 0 10px 0;
        font-family: $font-family-bold;
      }
      p {
        font-size: $font-size;
        font-family: $font-family;
      }
      .box-p {
        padding-bottom: 40px;
      }
    }

    .react-tabs__tab-list {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-bottom: 0;
      padding-bottom: 30px;
    }

    .react-tabs__tab {
      border: 0;
      border-bottom: 2px solid transparent;
      outline: 0;
      &:focus:after {
        border: 0;
        background: transparent;
      }
      span {
        display: block;
        text-align: center;
        font-family: $font-family-thin;
        font-size: $font-size;
        @include transition($animation);
        &:after {
          content: attr(tabname);
          display: block;
          font-size: $font-size-md;
          font-family: $font-family-regular;
          overflow: hidden;
          height: 0;
          visibility: hidden;
        }
      }
    }
    .react-tabs__tab--selected {
      font-size: $font-size-md;
      font-family: $font-family-regular;
      background-color: transparent;
      color: #ffffff;
      border-bottom: 2px solid $base-color;
      outline: 0;
      @include transition($animation);
      &:focus:after {
        border: 0;
        background: transparent;
      }
      span {
        display: block;
        text-align: center;
        font-size: $font-size-md;
        font-family: $font-family-regular;
        @include transition($animation);
        &:after {
          content: attr(tabname);
          display: block;
          font-size: $font-size-md;
          font-family: $font-family-regular;
          overflow: hidden;
          height: 0;
          visibility: hidden;
        }
      }
    }
    .react-tabs__tab-panel {
      position: relative;
      font-family: $font-family-light;
      font-size: $font-size;
      line-height: 1.8em;
      padding-right: 20px;
      @include transition($animation);
    }
  }

  .product-gallery {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 700px;
    padding-bottom: 30px;

    .slick-prev:before,
    .slick-next:before {
      font-family: "icomoon" !important;
      font-size: 13px;
    }
    .slick-prev:before {
      content: "\e901";
    }
    .slick-next:before {
      content: "\e902";
    }
    .slider-main {
      height: 75%;
      padding-bottom: 20px;
    }
    .slider-thumbs {
      height: 25%;
    }

    .product-img {
      position: relative;
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      height: 100%;
      width: 100%;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}
