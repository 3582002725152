@import "../../base.scss";

.form-materials-contact {
    position: relative;
    margin: auto;
    width: 100%;
    height: 100%;
    max-width: 320px;

    .content-step {
        position: relative;
        height: 100%;
        z-index: 1;
        padding-bottom: 80px;
        h2 {
            font-size: 20px;
            font-family: $font-family-bold;
        }
        &.step-2 {
            .form-row {
                overflow: hidden;
            }
            p {
                font-family: $font-family-thin;
                @include text-shadow($txt-shadow);
                padding-bottom: 45px;
                span {
                    font-family: $font-family-regular;
                }
            }
            .code {
                max-width: 295px;
                .row {
                    margin-left: -12px;
                    margin-right: -12px;
                    .col {
                        padding-left: 12px;
                        padding-right: 12px;
                    }
                }
            }
        }
    }
    .content-footer {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 70px;
        .bttn {
            .icon {
                padding-left: 10px;
                font-size: $font-size-xs;
            }
        }
    }

    .progress-line {
        position:relative;
        margin-top:25px;
        height:2px;
        width:100%;
        background-color:rgba(255,255,255,.25);
        .line {
            position: absolute;
            top:0;
            left:0;
            height:100%;
            background-color:#ffffff;
        }
    }
}
