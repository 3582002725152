@font-face {
  font-family:'CorsaGrotesk-Regular';
  src:url('fonts/CorsaGrotesk-Regular.eot');
  src:url('fonts/CorsaGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
       url('fonts/CorsaGrotesk-Regular.svg#CorsaGrotesk-Regular') format('svg'),
       url('fonts/CorsaGrotesk-Regular.ttf') format('truetype'),
       url('fonts/CorsaGrotesk-Regular.woff') format('woff'),
       url('fonts/CorsaGrotesk-Regular.woff2') format('woff2');
  font-weight:normal;
  font-style:normal;
}
@font-face {
  font-family:'CorsaGrotesk-Medium';
  src:url('fonts/CorsaGrotesk-Medium.eot');
  src:url('fonts/CorsaGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
       url('fonts/CorsaGrotesk-Medium.svg#CorsaGrotesk-Medium') format('svg'),
       url('fonts/CorsaGrotesk-Medium.ttf') format('truetype'),
       url('fonts/CorsaGrotesk-Medium.woff') format('woff'),
       url('fonts/CorsaGrotesk-Medium.woff2') format('woff2');
  font-weight:normal;
  font-style:normal;
}
@font-face {
  font-family:'CorsaGrotesk-Thin';
  src:url('fonts/CorsaGrotesk-Thin.eot');
  src:url('fonts/CorsaGrotesk-Thin.eot?#iefix') format('embedded-opentype'),
       url('fonts/CorsaGrotesk-Thin.svg#CorsaGrotesk-Thin') format('svg'),
       url('fonts/CorsaGrotesk-Thin.ttf') format('truetype'),
       url('fonts/CorsaGrotesk-Thin.woff') format('woff'),
       url('fonts/CorsaGrotesk-Thin.woff2') format('woff2');
  font-weight:normal;
  font-style:normal;
}
@font-face {
  font-family:'CorsaGrotesk-Light';
  src:url('fonts/CorsaGrotesk-Light.eot');
  src:url('fonts/CorsaGrotesk-Light.eot?#iefix') format('embedded-opentype'),
       url('fonts/CorsaGrotesk-Light.svg#CorsaGrotesk-Light') format('svg'),
       url('fonts/CorsaGrotesk-Light.ttf') format('truetype'),
       url('fonts/CorsaGrotesk-Light.woff') format('woff'),
       url('fonts/CorsaGrotesk-Light.woff2') format('woff2');
  font-weight:normal;
  font-style:normal;
}
@font-face {
  font-family:'CorsaGrotesk-Bold';
  src:url('fonts/CorsaGrotesk-Bold.eot');
  src:url('fonts/CorsaGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
       url('fonts/CorsaGrotesk-Bold.svg#CorsaGrotesk-Bold') format('svg'),
       url('fonts/CorsaGrotesk-Bold.ttf') format('truetype'),
       url('fonts/CorsaGrotesk-Bold.woff') format('woff'),
       url('fonts/CorsaGrotesk-Bold.woff2') format('woff2');
  font-weight:normal;
  font-style:normal;
}
@font-face {
  font-family:'CorsaGrotesk-Black';
  src:url('fonts/CorsaGrotesk-Black.eot');
  src:url('fonts/CorsaGrotesk-Black.eot?#iefix') format('embedded-opentype'),
       url('fonts/CorsaGrotesk-Black.svg#CorsaGrotesk-Black') format('svg'),
       url('fonts/CorsaGrotesk-Black.ttf') format('truetype'),
       url('fonts/CorsaGrotesk-Black.woff') format('woff'),
       url('fonts/CorsaGrotesk-Black.woff2') format('woff2');
  font-weight:normal;
  font-style:normal;
}
@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?vvx3jn');
  src:  url('fonts/icomoon.eot?vvx3jn#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?vvx3jn') format('truetype'),
    url('fonts/icomoon.woff?vvx3jn') format('woff'),
    url('fonts/icomoon.svg?vvx3jn#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-linkedIn:before {
  content: "\e910";
}
.icon-burger:before {
  content: "\e90e";
}
.icon-close-thin:before {
  content: "\e90f";
}
.icon-close:before {
  content: "\e90d";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-arrow-up:before {
  content: "\e903";
}
.icon-facebook:before {
  content: "\e904";
}
.icon-instagram:before {
  content: "\e905";
}
.icon-mail-full:before {
  content: "\e906";
}
.icon-mail:before {
  content: "\e907";
}
.icon-phone:before {
  content: "\e908";
}
.icon-pin-full:before {
  content: "\e909";
}
.icon-pin:before {
  content: "\e90a";
}
.icon-search:before {
  content: "\e90b";
}
.icon-twitter:before {
  content: "\e90c";
}
