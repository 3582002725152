@import "fonts";

// Fonts
$font-family: "CorsaGrotesk", Verdana, sans-serif;
$font-family-bold: "CorsaGrotesk-Bold", Verdana, sans-serif;
$font-family-medium: "CorsaGrotesk-Medium", Verdana, sans-serif;
$font-family-regular: "CorsaGrotesk-Regular", Verdana, sans-serif;
$font-family-black: "CorsaGrotesk-Black", Verdana, sans-serif;
$font-family-light: "CorsaGrotesk-Light", Verdana, sans-serif;
$font-family-thin: "CorsaGrotesk-Thin", Verdana, sans-serif;
$font-family-header: $font-family-regular;
// $font-family-header: Georgia, Times, Times New Roman, serif;

// Font-size
$font-size-xl: 40px;
// 38px h1 ?
$font-size-lg: 24px;
$font-size-md: 16px;
$font-size: 14px;
$font-size-sm: 12px;
$font-size-xs: 11px;
$font-size-xxs: 10px;

// Colors
$txt-color: #ffffff;
$txt-color-2:#2E2E2E;
$base-color:#1FECFF;
$color-2:#3E3E3E;
$base-color-opacity:rgba(31,236,255,.5);
$nav-color: #ffffff;

$body-bg: #434141;
$body-bg-m: #373838;
$overlay: rgba(32, 32, 32, 0.9);
$content-bg: rgba(62, 62, 62, 0.9);
$content-bg-2: rgba(62, 62, 62, 0.5);
$content-bg-3: rgba(32, 32, 32, 0.5);

$modalWhiteOverlay: rgba(0, 0, 0, 0.5);
$modalShadowColor: rgba(0, 0, 0, 0.18);

// Layout
/*
Typical resolutions for some popular screen sizes:
19-inch screen (standard ratio): 1280 x 1024 pixels.
20-inch screen (standard ratio): 1600 x 1200 pixels.
22-inch screen (widescreen): 1680 x 1050 pixels.
24-inch screen (widescreen): 1900 x 1200 pixels.
*/
$bp-desktop: 1900px;
$bp-laptop: 1440px;
$bp-responsive: 1280px;
$bp-tablet: 1024px;
$getscreen-mobile: 900px;
$bp-mobile: 768px;
$bp-mobile-sm: 680px;
$bp-mobile-xs: 480px;

$header-height: 120px;
$header-height-m: 85px;
$header-height-sm: 70px;
$container-width: 85%; // 1366px;
$container-width-m: 95%; // 1366px;

$container-space: 7.5%;
$container-space-2: 14%;

$sidespace-sm: 15px;
$sidespace: 25px;
$sidespace-md: 30px;
$sidespace-lg: 45px;
$sidespace-xl: 50px;
$sidespace-xxl: 60px;
$sidespace-xxxl: 75px;

$border-radius: 10px;
$border-radius-sm: 5px;

//forms
$txt-form-color: rgba(255,255,255,.5); // #4e4e4e;
$color-error: red;
$form-control-height: 40px;
$bttn-max-width: 240px;
// styles
$txt-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);

$box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
$box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.15);
$box-shadow-3: 0 5px 15px rgba(48, 48, 48, 1);
//@include box-shadow($box-shadow);

$animation: all 0.5s ease-in-out;

// cross-brawser styles

%flex {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
} //  @extend %flex;
%col {
    -ms-flex-preferred-size: 0;
    -ms-flex-positive: 1;
    max-width: 100%;
    flex-wrap: wrap;
}

// %animation {
//     -webkit-transition: all 0.5s ease-in-out;
//     -moz-transition: all 0.5s ease-in-out;
//     -ms-transition: all 0.5s ease-in-out;
//     -o-transition: all 0.5s ease-in-out;
//     transition: all 0.5s ease-in-out;
// }
// @include transition(all 0.5s ease-in-out);

%ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
} //  @extend %ellipsis;

@mixin transition($property) {
    -webkit-transition: $property;
    -moz-transition: $property;
    -ms-transition: $property;
    -o-transition: $property;
    transition: $property;
} // .clasname { @include transition(all 0.5s ease-in-out); }

@mixin transform($property) {
    -webkit-transform: $property;
    -moz-transform: $property;
    -ms-transform: $property;
    -o-transform: $property;
    transform: $property;
} // @include transform(translateY(-50%));

@mixin box-shadow($property) {
    -webkit-box-shadow: $property;
    -moz-box-shadow: $property;
    -ms-box-shadow: $property;
    box-shadow: $property;
} // @include box-shadow($box-shadow);

@mixin text-shadow($property) {
    -webkit-text-shadow: $property;
    -moz-text-shadow: $property;
    -ms-text-shadow: $property;
    text-shadow: $property;
} // @include text-shadow($txt-shadow);
