@import "../../base.scss";

.specialized-materials-items {
    .item {
        display:block;
        border: 1px solid $base-color-opacity;
        border-radius: $border-radius-sm;
        padding: 15px;
        margin-bottom: 25px;
        > a {
            display: block;
            text-decoration: none;
        }
    }
    .photo {
        position: relative;
        padding-top: 45%;
        display: block;
        overflow: hidden;
        // max-height:300px;
        margin-bottom: 15px;
        @include box-shadow($box-shadow);
        img {
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: auto;
            max-width: none;
        }
    }
    .info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        .info-name {
            display: block;
            font-size: $font-size-md;
            font-family: $font-family-medium;
        }
    }
}
